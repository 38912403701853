/**
 * TopBarPart
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect, useState } from 'react';

import { observer, useStore } from '@/pages/Core';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';
import { SubscriptionStore } from '@/store/subscription/subscription';

import { useConfirm, useI18n } from '@/hooks/core';
import { If, Link, Responsive } from '@/cutils';
import { useChatInfoContext, useChatMember, useChatMessageAction } from '@/hooks/apollo';

import { ChatType } from '@/codegen/graphql';

import { Dropdown } from '@exode.ru/vkui/dist/unstable';
import { Button, IconButton, Separator, SimpleCell, Text } from '@exode.ru/vkui';

import {
    Icon16Cancel,
    Icon24Attach,
    Icon24BlockOutline,
    Icon24DeleteOutline,
    Icon24MoreHorizontal,
    Icon24MuteOutline,
    Icon24PinOutline,
    Icon24ReplyOutline,
    Icon24SearchOutline,
    Icon24ShareOutline,
    Icon24VolumeOutline,
    Icon24WriteOutline,
    Icon28FavoriteOutline,
} from '@vkontakte/icons';

import { TopBarPartChatItem } from '../items/TopBarPartChatItem';
import { TopBarPartPersonalCreateItem } from '../items/TopBarPartPersonalCreateItem';

import { chatIconClass } from '../items/styled';


const TopBarPart = observer(() => {

    const { t } = useI18n('pages.Chat.Dialog');

    const [ show, setShow ] = useState(false);

    const { store, stater: { selectedMessages = {} } } = useStore(ChatDialogsPageStore);

    const {
        chat,
        personalUserId,
        handlePinMessage,
        handleEditMessage,
        handleReplyMessage,
        handleDeleteMessage,
        handleImportantMessage,
        personalCompanionIsBlocked,
    } = useChatInfoContext();

    const {
        updateChatMember,
        deleteChatMember,
        blockChatMemberPersonal,
        deleteChatMemberLoading,
        blockChatMemberPersonalLoading,
    } = useChatMember();

    const {
        isEditable,
        isReplyAble,
        isDeletable,
        isOpenedMenu,
    } = useChatMessageAction({ chat, selectedMessages });

    const { openConfirm: openConfirmChetMemberDelete } = useConfirm({
        onConfirm: () => deleteChatMember(chat?.id || 0),
        title: t('leaveChat'),
        subtitle: t('leaveChatConfirmation'),
        isLoading: deleteChatMemberLoading,
        buttons: {
            confirm: { text: t('leave') },
        },
    });

    const chatMemberPersonalBlockHandler = () => (
        blockChatMemberPersonal(
            chat?.id || 0,
            chat?.personalCompanionMember?.user?.id || 0,
            !personalCompanionIsBlocked || false,
        )
    );

    const { openConfirm: openConfirmChetMemberBlock } = useConfirm({
        onConfirm: chatMemberPersonalBlockHandler,
        title: t('blockChat'),
        subtitle: t('blockChatConfirmation'),
        isLoading: blockChatMemberPersonalLoading,
        buttons: {
            confirm: { text: t('block') },
        },
    });

    useEffect(() => {
        SubscriptionStore.subscribeToChatTypingIds(chat?.id);
    }, [ chat?.id ]);

    return (
        <>
            <If is={!isOpenedMenu}>
                <div style={{ width: 34 }}/>

                <If is={!!chat}>
                    <TopBarPartChatItem chat={chat!}/>
                </If>

                <If is={!chat && !!personalUserId}>
                    <TopBarPartPersonalCreateItem personalUserId={personalUserId as number}/>
                </If>

                <Dropdown shown={show}
                          action="click"
                          offsetSkidding={38}
                          offsetDistance={-35}
                          placement="left-start"
                          className="overflow-hidden"
                          onShownChange={setShow}
                          content={(
                              <div onClick={() => setShow(false)}>
                                  <div className="next-release">
                                      <SimpleCell before={<Icon24SearchOutline/>}
                                                  className="!rounded-none !rounded-t-lg">
                                          {t('search')}
                                      </SimpleCell>
                                  </div>

                                  <Link pushModal={{ id: 'chat-attachments' }}
                                        pushPageMobile={{ id: '/chat/attachments', params: { chatId: `${chat?.id}` } }}>
                                      <SimpleCell className="!rounded-none" before={<Icon24Attach/>}>
                                          {t('showAttachments')}
                                      </SimpleCell>
                                  </Link>

                                  <SimpleCell className="!rounded-none" before={(
                                      chat?.myMember?.muted
                                          ? <Icon24VolumeOutline/>
                                          : <Icon24MuteOutline/>
                                  )} onClick={() => updateChatMember(
                                      chat?.id || 0,
                                      { muted: !chat?.myMember?.muted },
                                  )}>
                                      {chat?.myMember?.muted ? t('turnOnSound') : t('turnOffSound')}
                                  </SimpleCell>

                                  <Separator wide/>

                                  <SimpleCell before={(
                                      chat?.type === ChatType.Personal
                                          ? <Icon24BlockOutline className="opacity-70" fill="var(--exode_red)"/>
                                          : <Icon24DeleteOutline className="opacity-70" fill="var(--exode_red)"/>
                                  )} className="!rounded-none !rounded-b-lg" onClick={(
                                      chat?.type === ChatType.Personal
                                          ? () => personalCompanionIsBlocked
                                              ? chatMemberPersonalBlockHandler()
                                              : openConfirmChetMemberBlock()
                                          : () => openConfirmChetMemberDelete()
                                  )} data-test={[
                                      'chat',
                                      chat?.type === ChatType.Personal
                                          ? (personalCompanionIsBlocked ? 'unlock' : 'lock')
                                          : 'leave',
                                  ].join('.')}>
                                      {chat?.type === ChatType.Personal
                                          ? (personalCompanionIsBlocked ? t('unblock') : t('block'))
                                          : t('leaveChat')
                                      }
                                  </SimpleCell>
                              </div>
                          )}>
                    <div>
                        <If is={!!chat}>
                            <div data-test="chat.show-options" onClick={() => setShow(show)} className={[
                                'mr-2',
                                show ? 'bg-hover rounded-full thin-border' : '',
                            ].join(' ')}>
                                <IconButton style={{ width: 34, height: 34 }}
                                            className="flex items-center justify-center rounded-full overflow-hidden">
                                    <Icon24MoreHorizontal fill="var(--accent)"
                                                          className={[ chatIconClass, '!p-0' ].join(' ')}/>
                                </IconButton>
                            </div>
                        </If>

                        <If is={!chat}>
                            <div/>
                        </If>
                    </div>
                </Dropdown>
            </If>

            <If is={isOpenedMenu}>
                <div className="flex items-center justify-between flex-1 m-2">
                    <div onClick={() => store.setSelectedMessages({})}
                         className="flex items-center gap-1 cursor-pointer">
                        <Text weight="regular" className="fs-14">
                            {selectedMessages?.[chat?.id ?? 0]?.length}
                            {' '}
                            {t('chats', { count: selectedMessages?.[chat?.id ?? 0]?.length })}
                        </Text>

                        <Icon16Cancel className={[
                            chatIconClass,
                            'text-accent self-center mt-[1px]',
                        ].join(' ')}/>
                    </div>

                    <div className="flex items-center gap-3 text-accent">
                        <If is={isEditable}>
                            <div onClick={handleEditMessage} data-test="chat.edit-message">
                                <Icon24WriteOutline className={chatIconClass}/>
                            </div>
                        </If>

                        <div className="next-release">
                            <Icon24PinOutline className={chatIconClass} onClick={handlePinMessage}/>
                        </div>

                        <If is={isDeletable}>
                            <div onClick={handleDeleteMessage} data-test="chat.delete-message">
                                <Icon24DeleteOutline width={22} height={22} className={chatIconClass}/>
                            </div>
                        </If>

                        <div className="next-release">
                            <Icon28FavoriteOutline width={24}
                                                   height={24}
                                                   className={chatIconClass}
                                                   onClick={handleImportantMessage}/>
                        </div>

                        <If is={isReplyAble}>
                            <div onClick={handleReplyMessage} data-test="chat.reply-message">
                                <Icon24ReplyOutline className={chatIconClass}/>
                            </div>
                        </If>

                        <Link pushModal={{
                            id: 'chat-forward-message',
                            params: { forwardChatId: `${chat?.id}` },
                        }}>
                            <Button className="thin-border" appearance="neutral">
                                <Responsive.Desktop>
                                    <>{t('forward')}</>
                                </Responsive.Desktop>

                                <Responsive.Mobile>
                                    <Icon24ShareOutline className={chatIconClass}/>
                                </Responsive.Mobile>
                            </Button>
                        </Link>
                    </div>
                </div>
            </If>
        </>
    );
});


export { TopBarPart };
