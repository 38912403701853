/**
 * MessageReaderWrapperItem
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { InView } from 'react-intersection-observer';

import { If } from '@/cutils';

import { ConfigStore } from '@/store/core/config';

import { ChatMessageItem } from '@/types/chat';
import { DocumentEvent } from '@/types/window';

import { observer, useStore } from '@/pages/Core';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';


interface Props {
    message: ChatMessageItem;
    children: ReactElement | ReactElement[];
}


const MessageReaderWrapperItem = observer((props: Props) => {

    const { children, message: { id, isMine, isRead } } = props;

    const { store } = useStore(ChatDialogsPageStore);

    const onInViewChange = (inView: boolean) => {
        if (inView && !isMine && !isRead) {
            ConfigStore.isInInteractive
                ? store.addUnreadMessageId(id)
                : document.addEventListener(
                    DocumentEvent.SystemWindowInteracted,
                    () => store.addUnreadMessageId(id),
                    { once: true },
                );
        }
    };

    return (
        <>
            <If is={!isMine && !isRead}>
                <InView skip={isRead} onChange={onInViewChange}>
                    {children}
                </InView>
            </If>

            <If is={isMine || isRead}>
                {children}
            </If>
        </>
    );
});


export { MessageReaderWrapperItem };
