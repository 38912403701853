/**
 * PreUploadedFilesView
 *
 * @author: exode <hello@exode.ru>
 */

import React, { Dispatch, SetStateAction } from 'react';

import { Avatar, Chip } from '@exode.ru/vkui';
import { Icon16CancelCircle } from '@vkontakte/icons';

import { If } from '@/cutils';
import { StorageFileType } from '@/shared/types';
import { UploadedFilePreviews } from '@/hooks/core';

import styles from '../ChatDialogsPage.module.scss';


interface Props {
    previews: UploadedFilePreviews[];
    setPreviews: Dispatch<SetStateAction<UploadedFilePreviews[]>>;
}


const PreUploadedFilesView = (props: Props) => {

    const { previews, setPreviews } = props;

    const removeFromFiles = (id: string) => {
        setPreviews((prev) => prev.filter((item) => item.id !== id));
    };

    return (
        <If is={previews.length > 0}>
            <div className="flex p-2 gap-2 flex-wrap thin-border-top">
                {previews?.map(({ id, src, type, info }) => (
                    <div key={id}>
                        <If is={type === StorageFileType.Image}>
                            <Avatar key={id}
                                    src={src}
                                    size={64}
                                    mode="image"
                                    className={[ styles.filePreview, 'thin-border' ].join(' ')}
                                    badge={(
                                        <Icon16CancelCircle fill="var(--accent)"
                                                            className="bg-content"
                                                            onClick={() => removeFromFiles(id)}/>
                                    )}/>
                        </If>

                        <If is={type === StorageFileType.Video}>
                            <Avatar key={id}
                                    size={64}
                                    mode="image"
                                    className={[ styles.filePreview, 'thin-border' ].join(' ')}
                                    badge={(
                                        <Icon16CancelCircle fill="var(--accent)"
                                                            className="bg-content"
                                                            onClick={() => removeFromFiles(id)}/>
                                    )}>

                                <div className="text-accent rounded-full flex items-center justify-center uppercase">
                                    {info?.fileExtension ? info.fileExtension : '?'}
                                </div>

                                <Chip className={`${styles.chip} top-0 left-0`} removable={false} value="">
                                    {info?.size}
                                </Chip>

                                <Chip className={`${styles.chip} top-0 right-0`} removable={false} value="">
                                    {info?.duration?.toFixed(2)}
                                </Chip>

                                <Chip className={`${styles.chip} bottom-0 left-0`} removable={false} value="">
                                    {info?.name}
                                </Chip>
                            </Avatar>
                        </If>

                        <If is={type === StorageFileType.File}>
                            <Avatar key={id}
                                    size={64}
                                    mode="image"
                                    className={[ styles.filePreview, 'thin-border' ].join(' ')}
                                    badge={(
                                        <Icon16CancelCircle fill="var(--accent)"
                                                            className="bg-content"
                                                            onClick={() => removeFromFiles(id)}/>
                                    )}>
                                <div className="text-accent rounded-full flex items-center justify-center uppercase">
                                    {info?.fileExtension ? info.fileExtension : '?'}
                                </div>

                                <Chip className={`${styles.chip} top-0 left-0`} removable={false} value="">
                                    {info?.size}
                                </Chip>

                                <Chip className={`${styles.chip} bottom-0 left-0`} removable={false} value="">
                                    {info?.name}
                                </Chip>
                            </Avatar>
                        </If>
                    </div>
                ))}
            </div>
        </If>
    );
};


export { PreUploadedFilesView };
