/**
 * ChecklistContentElementShow
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useRef } from 'react';

import { Formik, FormikProps } from 'formik';

import { AutoSaveForm, If } from '@/cutils';
import { ChecklistContent, ChecklistInteraction } from '@/shared/types';

import { Checkbox } from '@exode.ru/vkui';
import { Icon24CheckBoxOn } from '@vkontakte/icons';

import { BlockTitle } from '@/components/Content/BlockTitle';

import { ContentElementShowProps } from '../interfaces';
import { useMyInteractionReinitialize } from '../helper';


interface Props extends ContentElementShowProps<ChecklistContent, ChecklistInteraction> {

}


const ChecklistContentElementShow = (props: Props) => {

    const {
        interaction,
        contentElement: {
            uuid,
            title,
            myInteraction,
            content: { items },
        },
    } = props;

    const formikRef = useRef<FormikProps<ChecklistInteraction>>(null);

    /** Listen and reinitialize form for syncing */
    useMyInteractionReinitialize(uuid, formikRef);

    return (
        <Formik innerRef={formikRef}
                data-test="checklist.formik"
                onSubmit={(value) => interaction?.interact(value)}
                initialValues={myInteraction?.data || { items: {} }}>
            {({ values, handleSubmit, setFieldValue }) => (
                <>
                    <If is={!!title}>
                        <BlockTitle title={title} className="d:mb-3 my-4"/>
                    </If>

                    <AutoSaveForm onChange={handleSubmit}>
                        <div className="flex flex-1 flex-col justify-center gap-2 mb-3">
                            {items.map((item, index) => {
                                const isChecked = values.items[item.uuid]?.checked ?? !!item.defaultChecked;

                                return (
                                    <div key={index} className="flex items-center gap-2">
                                        <Checkbox checked={isChecked}
                                                  disabled={item.disabled}
                                                  description={item.text?.split('//')?.[1]}
                                                  onIcon={<Icon24CheckBoxOn fill="var(--dynamic_green)"/>}
                                                  className="checkbox-origin w-full bg-hover-50 py-1 vk-rounded"
                                                  onChange={(e) => {
                                                      setFieldValue(
                                                          `items[${item.uuid}].checked`,
                                                          e.target.checked,
                                                      );
                                                  }}>
                                            <span className={[
                                                'fs-18',
                                                isChecked ? 'line-through text-secondary' : '',
                                            ].join(' ')}>
                                                {item.text?.split('//')?.[0]}
                                            </span>
                                        </Checkbox>
                                    </div>
                                );
                            })}
                        </div>
                    </AutoSaveForm>
                </>
            )}
        </Formik>
    );
};


export { ChecklistContentElementShow };
