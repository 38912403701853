/**
 * ButtonContentElementShow
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { useRedirect } from '@/cutils';
import { ButtonContent, ButtonInteraction } from '@/shared/types';

import { Button, ButtonGroup } from '@exode.ru/vkui';

import { ContentElementShowProps } from '@/components/ContentElement';


interface Props extends ContentElementShowProps<ButtonContent, ButtonInteraction> {

}


const ButtonContentElementShow = (props: Props) => {

    const {
        interaction,
        contentElement: {
            myInteraction,
            content: { buttons },
        },
    } = props;

    const { redirect } = useRedirect();

    return (
        <ButtonGroup className="gap-3 justify-center w-full">
            {buttons.map((button) => (
                <Button size="l" key={button.uuid} data-ce={`button.${button.uuid}`} style={{
                    color: button.textColor || 'var(--text_primary)',
                    background: button.bgColor || 'var(--accent)',
                }} onClick={() => {
                    interaction?.interact({
                        clicks: _.orderBy([
                            { uuid: button.uuid, time: new Date() },
                            ...(myInteraction?.data?.clicks || []),
                        ], 'time', 'desc').splice(0, 25),
                    });

                    redirect(button.link, button.target === '_blank');
                }}>
                    {button.text}
                </Button>
            ))}
        </ButtonGroup>
    );
};


export { ButtonContentElementShow };
