/**
 * UseChatMessageAction
 *
 * @author: exode <hello@exode.ru>
 */

import { ChatFindOneInfo, ChatMessageItem } from '@/types/chat';


interface Props {
    chat?: ChatFindOneInfo;
    selectedMessages: Record<number, ChatMessageItem[]>;
}


export const useChatMessageAction = (props: Props) => {

    const { chat, selectedMessages } = props;

    const isOpenedMenu = !!selectedMessages?.[chat?.id ?? 0]?.length;
    const isReplyAble = !!chat?.id && selectedMessages?.[chat?.id]?.length === 1;

    const isDeletable = (() => {
        if (!chat?.id || !selectedMessages?.[chat?.id]?.length) {
            return false;
        }

        return selectedMessages[chat.id]?.every(({ isEditable }) => isEditable);
    })();

    const isEditable = (() => {
        if (!chat?.id || selectedMessages?.[chat?.id]?.length !== 1) {
            return false;
        }

        return selectedMessages[chat?.id ?? 0][0]?.isEditable;
    })();

    return {
        isOpenedMenu,
        isReplyAble,
        isDeletable,
        isEditable,
    };
};
