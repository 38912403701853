/**
 * GroupSearchSelect
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { FilterGroupInput, useGroupFindManyLazyQuery } from '@/codegen/graphql';

import { SearchSelect, SelectSearchOption } from '@/components/Atoms/SearchSelect';


interface Props {
    onSelect: (params: any) => void;
    selectedGroupIds?: number[];
    filter?: FilterGroupInput;
    extraOptions?: SelectSearchOption[];
}


const GroupSearchSelect = (props: Props) => {

    const {
        onSelect,
        extraOptions,
        selectedGroupIds,
        filter = {},
    } = props;

    const { t } = useI18n('components.Group.GroupSearchSelect');

    const [ search, { data, loading, variables } ] = useGroupFindManyLazyQuery({
        fetchPolicy: 'network-only',
        onError: error => console.error(error),
    });

    const options = data?.groupFindMany.items?.map(({ id, name }) => ({
        value: id,
        label: name,
    }));

    const searchCallback = async (value: string) => {
        await search({
            variables: {
                list: { take: 100 },
                filter: { ...filter, search: value },
            },
        });
    };

    return (
        <SearchSelect loading={loading}
                      onSelect={onSelect}
                      selectedIds={selectedGroupIds}
                      placeholder={t('enterGroupName')}
                      searchCallback={searchCallback}
                      initialSearch={{ deps: [], skip: false }}
                      options={[
                          ...(extraOptions || []),
                          ...(options || []),
                      ]}
                      emptyText={(
                          variables?.filter?.search
                              ? t('searchDidNotGiveResults')
                              : t('startTypingGroupName')
                      )}/>
    );
};


export { GroupSearchSelect };
