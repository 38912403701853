/**
 * UsePaymentCart
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { useLayoutEffect, useState } from 'react';

import { APP_URL, IS_NATIVE } from '@/root/src/env';

import { ShopStore } from '@/store/user/shop';
import { PlatformStore } from '@/store/platform';
import { UserAuthStore } from '@/store/user/auth';
import { PreferenceStore } from '@/store/preference/preference';
import { Router as RouterService } from '@/services/Utils/Router';

import { useLocation } from '@/router/index';
import { Notify, useRedirect } from '@/cutils';
import { ProductCurrency } from '@/shared/types';
import { useI18n, useNative } from '@/hooks/core';

import {
    CartProduct,
    PaymentStatus,
    useCartUpsertMutation,
    useDiscountCheckCodeLazyQuery,
    useInvoiceCreateByCartMutation,
    useInvoiceCreateByCartUnauthMutation,
    useInvoiceCreateBySharedCartMutation,
    useInvoiceRecreatePaymentMutation,
} from '@/codegen/graphql';


interface Props {
    cartUuid?: string;
    currentPromoCode?: string;
}


export const usePaymentCart = (props: Props = {}) => {

    const { cartUuid, currentPromoCode } = props;

    const { t } = useI18n('hooks.apollo.payment');

    const { redirect } = useRedirect();
    const { triggerHaptic } = useNative();
    const { route: { params } } = useLocation();

    const [ promoCode, setPromoCode ] = useState(currentPromoCode || '');
    const [ promoCodeError, setPromoCodeError ] = useState('');
    const [ hasPromoInput, setHasPromoInput ] = useState(!!currentPromoCode);

    const [ sharedCartToCopyLink, setSharedCartToCopyLink ] = useState('');

    const [ checkDiscount, { loading: checkDiscountLoading } ] = useDiscountCheckCodeLazyQuery({
        onError: error => setPromoCodeError(error.message),
        onCompleted: ({ discountCheckCode }) => {
            if (discountCheckCode) {
                ShopStore.merge({
                    promoCodes: _.uniq([
                        ...ShopStore.promoCodes,
                        discountCheckCode.code,
                    ]),
                });
            }
        },
    });

    const [
        _upsertCartShared,
        { loading: upsertCartSharedLoading },
    ] = useCartUpsertMutation({
        onError: (error) => console.error(error),
    });

    const [
        _createInvoice,
        { loading: createInvoiceLoading },
    ] = useInvoiceCreateByCartMutation({
        onError: error => console.error(error),
        onCompleted: ({ invoiceCreateByCart }) => {
            const { uuid, actualPayment } = invoiceCreateByCart;

            checkoutStatusAction({
                invoiceUuid: uuid,
                checkoutUrl: actualPayment?.checkoutUrl,
                actualPaymentStatus: actualPayment?.status,
            });
        },
    });

    const [
        _createInvoiceUnauth,
        { loading: createInvoiceUnauthLoading },
    ] = useInvoiceCreateByCartUnauthMutation({
        onError: error => console.error(error),
        onCompleted: ({ invoiceCreateByCartUnauth }) => {
            const { uuid, actualPayment } = invoiceCreateByCartUnauth;

            checkoutStatusAction({
                invoiceUuid: uuid,
                checkoutUrl: actualPayment?.checkoutUrl,
                actualPaymentStatus: actualPayment?.status,
            });
        },
    });

    const [
        _createInvoiceShared, {
            loading: createInvoiceSharedLoading,
        } ] = useInvoiceCreateBySharedCartMutation({
        onError: error => console.error(error),
        onCompleted: ({ invoiceCreateBySharedCart: invoiceCreateByCart }) => {
            checkoutStatusAction({
                invoiceUuid: invoiceCreateByCart.uuid,
                checkoutUrl: invoiceCreateByCart.actualPayment?.checkoutUrl,
                actualPaymentStatus: invoiceCreateByCart.actualPayment?.status,
            });
        },
    });

    const [ recreateInvoicePayment, {
        loading: recreateInvoicePaymentLoading,
    } ] = useInvoiceRecreatePaymentMutation({
        onError: error => console.error(error),
        onCompleted: ({ invoiceRecreatePayment }) => {
            checkoutStatusAction({
                invoiceUuid: invoiceRecreatePayment.uuid,
                checkoutUrl: invoiceRecreatePayment.actualPayment?.checkoutUrl,
                actualPaymentStatus: invoiceRecreatePayment.actualPayment?.status,
            });
        },
    });

    const upsertCartShared = async (cb?: (link: string) => void) => {
        await _upsertCartShared({
            variables: {
                cart: {
                    isShared: true,
                    products: ShopStore.cart,
                    promoCodes: [ ...ShopStore.promoCodes ],
                },
            },
            onCompleted: async (data) => {
                const link = `${APP_URL}/cart/${data.cartUpsert.uuid}`;

                setSharedCartToCopyLink(link);

                cb?.(link);
            },
        });
    };

    const createInvoice = async (
        acquiringId: number | 0 | undefined,
        currency: ProductCurrency | undefined | null,
        products: CartProduct[],
    ) => {
        const { COMMON } = PlatformStore;

        currency = acquiringId === 0
            ? COMMON?.organization.settings.currency
            : currency;

        if (_.isNil(acquiringId) || _.isNil(currency)) {
            return;
        }

        const cart = {
            currency,
            products,
            acquiringId,
            promoCodes: [ ...ShopStore.promoCodes ],
        };

        return UserAuthStore.isLoggedIn
            ? _createInvoice({ variables: { cart } })
            : _createInvoiceUnauth({
                variables: {
                    cart,
                    payer: _.pick(ShopStore.paymentFormDto, 'login'),
                },
            });
    };

    const createInvoiceShared = async (
        acquiringId: number | 0 | undefined,
        currency: ProductCurrency | undefined | null,
    ) => {
        const { COMMON } = PlatformStore;

        currency = acquiringId === 0
            ? COMMON?.organization.settings.currency
            : currency;

        if (_.isNil(acquiringId) || _.isNil(currency)) {
            return;
        }

        await _createInvoiceShared({
            variables: {
                sharedCart: {
                    currency,
                    acquiringId,
                    uuid: cartUuid ?? '',
                    promoCodes: [ ...ShopStore.promoCodes ],
                },
            },
        });
    };

    const handleApplyPromoClick = (
        _hasPromoInput = hasPromoInput,
        _promoCode = promoCode,
    ) => {
        _hasPromoInput
            ? _promoCode && checkDiscount({ variables: { code: _promoCode } })
            : setHasPromoInput(true);
    };

    const resetPromoCode = () => {
        if (params.promo) {
            RouterService.updateParams({ promo: '' });
        }

        ShopStore.merge({
            promoCodes: ShopStore.promoCodes.filter((v) => v !== promoCode),
        });

        setPromoCode('');
        setPromoCodeError('');
    };

    const checkoutStatusAction = (props: {
        actualPaymentStatus: PaymentStatus | undefined;
        invoiceUuid: string;
        checkoutUrl?: string | null;
    }) => {
        const {
            checkoutUrl,
            invoiceUuid,
            actualPaymentStatus,
        } = props;

        switch (actualPaymentStatus) {
            case PaymentStatus.WaitingPay:
                if (checkoutUrl) {
                    triggerHaptic();
                    redirect(checkoutUrl, false, true);
                }

                if (!IS_NATIVE) {
                    PreferenceStore.merge({ spinner: true });
                }

                ShopStore.merge({ promoCodes: [] }, !cartUuid);

                /** Remove spinner and open pay page if opened by native bank app */
                setTimeout(() => {
                    PreferenceStore.merge({ spinner: false });

                    RouterService.pushPage(
                        '/payment/:invoiceUuid([0-9_A-Za-z_-]+)',
                        { invoiceUuid },
                    );
                }, 3500);

                return;

            case PaymentStatus.Completed:
                triggerHaptic();
                ShopStore.merge({ promoCodes: [] }, !cartUuid);

                return RouterService.pushPage(
                    '/payment/:invoiceUuid([0-9_A-Za-z_-]+)',
                    { invoiceUuid },
                );

            case PaymentStatus.Created:
            case PaymentStatus.Canceled:
                return Notify.vkui({
                    appearance: 'error',
                    message: t('somethingWentWrongRepeat'),
                });
        }
    };

    useLayoutEffect(() => {
        const { promo } = params;

        if (promo && promoCode !== promo) {
            setPromoCode(promo);
            setHasPromoInput(true);

            handleApplyPromoClick(true, promo);
        }
    }, [ params.promo ]);

    return {
        hasPromoInput,
        promoCode,
        promoCodeError,
        checkDiscountLoading,
        createInvoice,
        upsertCartShared,
        upsertCartSharedLoading,
        sharedCartToCopyLink,
        createInvoiceShared,
        createInvoiceSharedLoading,
        recreateInvoicePayment,
        recreateInvoicePaymentLoading,
        setPromoCode,
        setPromoCodeError,
        resetPromoCode,
        handleApplyPromoClick,
        createInvoiceLoading: createInvoiceLoading || createInvoiceUnauthLoading,
    };
};
