/**
 * ManageCoursesListPage store
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { makeObservable } from 'mobx';
import { UserAuthStore } from '@/store/user/auth';
import { observer, PageStore } from '@/pages/Core';
import { UserRbacService } from '@/services/User/Rbac';

import {
    CourseProgressType,
    CourseType,
    EducationSubject,
    Permission,
    ProductCurrency,
    ProductLaunchStatus,
} from '@/codegen/graphql';


/** Перемещен в store для решения circular dependency */
export const defaultFilter = {
    search: '',
    manage: true,
    type: CourseType.VideoCourse,
    progressTypes: [] as CourseProgressType[],
    subjects: [] as EducationSubject[],
    productLaunchStatuses: [] as ProductLaunchStatus[],
    productArchived: undefined as unknown as boolean,
    productIsOnSale: null as unknown as boolean,
    productIsFree: null as unknown as boolean,
};

export const dtoFilterTransformer = (filter: typeof defaultFilter) => {

    const administrate = UserRbacService.canAny(
        UserAuthStore.permissions,
        Permission.UserIsAdmin,
    );

    return {
        ..._.omit(filter, [
            'type',
            'productIsFree',
            'productIsOnSale',
            'productArchived',
            'productLaunchStatuses',
        ]),
        administrate,
        manage: !administrate,
        types: [ filter.type ].filter(e => e),
        product: {
            launch: { statuses: filter.productLaunchStatuses },
            /** Accept only true (ignore false) */
            isOnSale: `${filter.productIsOnSale}` === 'true'
                ? true
                : undefined,
            currencies: filter.productIsFree
                ? (
                    `${filter.productIsFree}` === 'true'
                        ? [ ProductCurrency.Free ]
                        : _.values(ProductCurrency).filter((e) => e !== ProductCurrency.Free)
                )
                : null,
        },
    };
};


class ManageCoursesListPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        list: {
            skip: 0,
            take: 15,
        },
        filter: dtoFilterTransformer(defaultFilter),
        sort: {},
    };

}

const ManageCoursesListPageStore = new ManageCoursesListPage();


export { observer, ManageCoursesListPageStore };
