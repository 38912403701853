/**
 * ContentLayoutBlockManage
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { nanoid } from 'nanoid';

import React, { ComponentType, ReactNode, useRef, useState } from 'react';

import { useEditorJs, useI18n } from '@/hooks/core';

import { ContentElementType } from '@/codegen/graphql';
import { contentElementDescription, contentElementType } from '@/types/content';

import { If } from '@/cutils';
import { ScrollHelper } from '@/helpers/ui';
import { useArticleBlockManage, useLessonBlockManage, useTaskBlockManage } from '@/hooks/apollo';

import { ContentElementEditProps, ContentElementItem } from '@/components/ContentElement';

import { ContentBlocksListPart } from './parts/ContentBlocksListPart';
import { EmptyContentBlocksPart } from './parts/EmptyContentBlocksPart';
import { ButtonGroupWrapperPart } from './parts/ButtonGroupWrapperPart';
import { AddBlockButtonPart, AddBlockButtonPartProps } from './parts/AddBlockButtonPart';


export interface ContentLayoutBlockManageProps {
    blocks: ContentElementItem[];
    actions: ReturnType<typeof useLessonBlockManage>
        | ReturnType<typeof useArticleBlockManage>
        | ReturnType<typeof useTaskBlockManage>;
    setBlockEditing: () => void;
    options: ContentElementEditProps['options'];
    placement?: 'page' | 'card';
    className?: string;
    extraButtons?: AddBlockButtonPartProps[];
    emptyPlaceholder?: ReactNode;
    includeTypes?: ContentElementType[];
    ButtonWrapper?: ComponentType;
    addBlockButtonPartProps?: Partial<AddBlockButtonPartProps>;
}


const ContentLayoutBlockManage = (props: ContentLayoutBlockManageProps) => {

    const {
        blocks,
        actions,
        options,
        className,
        extraButtons,
        includeTypes,
        setBlockEditing,
        addBlockButtonPartProps,
        placement = 'page',
        ButtonWrapper = ButtonGroupWrapperPart,
        emptyPlaceholder = <EmptyContentBlocksPart/>,
    } = props;

    const { t } = useI18n('components.Content.ContentLayoutBlockManage');

    const contentElementTypes = contentElementType();
    const contentElementDescriptions = contentElementDescription();

    const blockRefs = useRef<HTMLDivElement[]>([]);

    const [ loadingType, setLoadingType ] = useState<ContentElementType>();

    const { getEditorJsInitial } = useEditorJs();

    const {
        createBlock,
        createBlockLoading,
    } = actions;

    /** Handle block add */
    const handleAddBlock = async (
        type: ContentElementType,
        initialContent = {},
    ) => {
        await createBlock({
            type,
            title: '',
            content: initialContent,
        });

        /** Scroll to new block */
        setTimeout(() => {
            if (placement === 'page') {
                ScrollHelper.to(
                    (blockRefs.current?.at(-1)?.offsetTop || 0) + 2,
                    true,
                );
            }
        }, 100);
    };

    const buttons = {
        [ContentElementType.EditorJsBlock]: {
            onClick: () => {
                return handleAddBlock(
                    ContentElementType.EditorJsBlock,
                    getEditorJsInitial(),
                );
            },
        },
        [ContentElementType.Video]: {
            onClick: () => {
                return handleAddBlock(ContentElementType.Video);
            },
        },
        [ContentElementType.Audio]: {
            onClick: () => {
                return handleAddBlock(ContentElementType.Audio);
            },
        },
        [ContentElementType.Button]: {
            onClick: () => {
                return handleAddBlock(ContentElementType.Button, {
                    buttons: [ {
                        uuid: nanoid(),
                        link: '',
                        textColor: '',
                        bgColor: '',
                        target: '_blank',
                        text: t('goTo'),
                    } ],
                });
            },
        },
        [ContentElementType.Checklist]: {
            onClick: () => {
                return handleAddBlock(ContentElementType.Checklist, {
                    items: [
                        {
                            uuid: nanoid(),
                            text: '',
                            defaultChecked: false,
                            disabled: false,
                        },
                    ],
                });
            },
        },
        [ContentElementType.NotionPage]: {
            onClick: () => {
                return handleAddBlock(ContentElementType.NotionPage, {
                    notionUrl: '',
                });
            },
        },
    } as const;

    return (
        <>
            <If is={!_.isEmpty(blocks)}>
                <ContentBlocksListPart blocks={blocks}
                                       actions={actions}
                                       options={options}
                                       blockRefs={blockRefs}
                                       placement={placement}
                                       className={className}
                                       setBlockEditing={setBlockEditing}/>
            </If>

            <If is={_.isEmpty(blocks)}>
                <>{emptyPlaceholder}</>
            </If>

            <ButtonWrapper>
                <div className="px-0 py-2 grid grid-cols-3 gap-4">
                    {_.map({ ...buttons }, ({ onClick }, type: ContentElementType) => (
                        <If key={type} is={!includeTypes || includeTypes?.includes(type)}>
                            <AddBlockButtonPart key={type}
                                                type={type}
                                                loadingType={loadingType}
                                                loading={createBlockLoading}
                                                text={contentElementTypes[type]}
                                                tooltip={contentElementDescriptions[type]}
                                                onClick={() => {
                                                    onClick?.();
                                                    setLoadingType(type);
                                                }} {...addBlockButtonPartProps}/>
                        </If>
                    ))}

                    {_.map(extraButtons, (button, index) => (
                        <AddBlockButtonPart key={index} {...button} {...addBlockButtonPartProps}/>
                    ))}
                </div>
            </ButtonWrapper>
        </>
    );
};


export { ContentLayoutBlockManage };
