/**
 * TopBarPartChatItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { APP_URL, IS_NATIVE } from '@/root/src/env';

import { useLocation } from '@/router/index';

import { useStore } from '@/pages/Core';
import { ChatDialogsPageStore, observer } from '@/pages/Chat/Dialog/store';

import { ChatFindOneInfo } from '@/types/chat';
import { ChatType, UserSexType } from '@/codegen/graphql';

import { If, Link } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { Caption, Text } from '@exode.ru/vkui';

import { LastOnlineText } from '@/components/Profile/Page';
import { ProfilePartial, User } from '@/components/Atoms/User';

import { TypingItem } from '../items/TypingItem';


interface Props {
    chat: ChatFindOneInfo;
}


const TopBarPartChatItem = observer((props: Props) => {

    const { chat } = props;

    const { t } = useI18n('pages.Chat.TopBarPartChatItem');

    const { store } = useStore(ChatDialogsPageStore);
    const { route: { params } } = useLocation();

    const isTyping = store.chatIsTyping(chat.id);
    const headerUserId = chat?.personalCompanionMember?.user?.id;

    return (
        <div className="flex-1 px-4 flex-col items-center justify-center text-center grid">
            <Link blank={!IS_NATIVE}
                  disabled={chat.type !== ChatType.Personal}
                  toOuter={!IS_NATIVE ? `${APP_URL}/@${headerUserId}` : ''}
                  pushPage={{
                      id: '/@:userId([0-9_A-Za-z]+)',
                      params: { userId: `${headerUserId}` },
                  }}>
                <Text weight="regular"
                      data-test="chat.topbar.name"
                      className="text-ellipsis w-full overflow-x-hidden whitespace-nowrap m-auto">
                    <If is={chat.type !== ChatType.Personal}>
                        <>{chat.name}</>
                    </If>

                    <If is={chat.type === ChatType.Personal}>
                        <User.Name className="justify-center"
                                   profile={chat.personalCompanionMember?.user.profile as ProfilePartial}/>
                    </If>
                </Text>
            </Link>

            <If is={!isTyping}>
                <Caption className="text-subhead" weight="regular" level="2">
                    <If is={chat.type === ChatType.Personal}>
                        <LastOnlineText userId={chat?.personalCompanionMember?.user?.id ?? 0}
                                        lastOnlineAt={chat.personalCompanionMember?.user?.lastOnlineAt}
                                        sex={chat.personalCompanionMember?.user?.profile?.sex as UserSexType}/>
                    </If>

                    <If is={chat.type !== ChatType.Personal}>
                        <Link pushModal={{ id: 'chat-members', params: { chatId: params.chatId, page: '1' } }}
                              pushPageMobile={{
                                  id: '/chat/members/:page([0-9]+)',
                                  params: { chatId: params.chatId },
                              }}>
                            <span>
                                {t('participant', { count: chat.countMembers ?? 0 })}
                            </span>
                        </Link>
                    </If>
                </Caption>
            </If>

            <If is={isTyping}>
                <TypingItem chat={chat} className="m-auto" typingState={store.state.chatsTypings?.[chat.id]}/>
            </If>
        </div>
    );
});


export { TopBarPartChatItem };
