/**
 * UserAuthService
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { Cookie } from '@/api/cookie';
import { Storage } from '@/api/storage';
import { RestApi } from '@/api/restapi';
import { LocalStorageKeyType } from '@/types/config';

import { UserAuthStore } from '@/store/user/auth';
import { PreferenceStore } from '@/store/preference/preference';

import { IS_MARKETPLACE, IS_NATIVE, OS_PLATFORM } from '@/root/src/env';

import {
    ConnectCallbackTelegramInput,
    LoginAuthOutput,
    LogoutAuthOutput,
    RecoverPasswordOutput,
    ServiceLoginAuthOutput,
    SetOtpAuthInput,
    SignupAuthOutput,
    UpdatePasswordInput,
    VkAuthMode,
    VkIdLoginAuthInput,
} from '@/codegen/graphql';

import { Url } from '@/utils';
import { Router } from '@/services/Utils/Router';
import { BaseService } from '@/services/Core/Base';
import { RecoverPasswordInput } from '@/libs/class-validator';


class UserAuthService extends BaseService {

    /**
     * Инициализация сервиса
     */
    static init() {
        UserAuthService.restoreAuthStateToStorage();

        const token = Storage.get('user:auth-token');

        token
            ? UserAuthStore.authByToken(token)
            : UserAuthStore.cleanOnLogout(false, 'onInit');
    }

    /**
     * Регистрация пользователя.
     * Если не существует и передан верный код, то регистрируем и входим.
     * @param data
     * @returns {}
     */
    static async signup(
        data: {
            login: string;
            code?: string;
        },
    ) {
        const result = await RestApi.post('auth/signup', data);

        return this.parse<SignupAuthOutput & { session: { token: string } }>(result);
    }

    /**
     * Вход через логин и пароль
     * @param {} data
     * @returns {}
     */
    static async login(
        data: {
            login: string;
            password: string;
            otp?: string;
        },
    ) {
        const result = await RestApi.post('auth/login', data);

        return this.parse<LoginAuthOutput & { session: { token: string } }>(result);
    }

    /**
     * Вход через VK ID
     * @param {} mode
     * @param {} data
     * @returns {Promise<>}
     */
    static async vkTokenLogin(
        mode: VkAuthMode,
        data: VkIdLoginAuthInput,
    ) {
        return RestApi.post(`auth/${mode}/login`, data) as Promise<ServiceLoginAuthOutput>;
    }

    /**
     * Вход через TG
     * @param {} callbackAuthInput
     * @returns {Promise<>}
     */
    static async tgLoginCallback(
        callbackAuthInput: ConnectCallbackTelegramInput,
    ) {
        return RestApi.post(`auth/telegram/callback`, { callbackAuthInput }) as Promise<ServiceLoginAuthOutput>;
    }

    /**
     * Выход из аккаунта
     * @returns {}
     */
    static async logout() {
        const result = await RestApi.post('auth/logout');

        return this.parse<LogoutAuthOutput>(result);
    }

    /**
     * Отправка письма для восстановления пароля
     * @param data
     * @returns {}
     */
    static async recover(data: RecoverPasswordInput) {
        const result = await RestApi.post('password/recover', data);

        return this.parse<RecoverPasswordOutput>(result);
    }

    /**
     * Изменение пароля
     * @param data
     * @returns {}
     */
    static async update(data: UpdatePasswordInput & { signature?: string }) {
        const result = await RestApi.post('password/update', data);

        return this.parse<any>(result);
    }

    /**
     * Изменение пароля авторизованным пользователем
     * @param data
     * @returns {}
     */
    static async updatePassword(data: UpdatePasswordInput) {
        const result = await RestApi.post('password/update/authed', data);

        return this.parse<any>(result);
    }

    /**
     * Установка двух факторной аутентификации
     * @param data
     * @returns {}
     */
    static async setOtp(data: SetOtpAuthInput) {
        const result = await RestApi.post('auth/otp/set', data);

        return this.parse<any>(result);
    }

    /**
     * Получение auth state для native
     */
    static getNativeAuthStateQuery() {
        return Url.objectToQuery({
            ...Router.routerParams,
            state: 'redirect:true,inapp:true',
        });
    }

    /**
     * Сохранение состояния авторизации в "куки"
     */
    static saveAuthStateToCookie() {
        const lsKeys: LocalStorageKeyType[] = [
            'seller:id',
            'user:auth-token',
            'preference:scheme',
            'fcm-notifications:asked',
        ];

        Cookie.set(
            'auth:restore',
            _.mapValues(
                _.keyBy(lsKeys), (__, key: typeof lsKeys[number]) => Storage.get(key),
            ),
        );
    }

    /**
     * Восстановление состояния авторизации из "куки"
     */
    static restoreAuthStateToStorage() {
        const saved = Cookie.get('auth:restore');

        Cookie.destroy('auth:restore');

        if (saved) {
            PreferenceStore.merge({ spinner: true });
        }

        for (const [ key, value ] of _.entries(saved || {})) {
            Storage.set(key as LocalStorageKeyType, value);
        }
    }

    /**
     * Доступные методы входа
     * @param {'email'[]} exclude
     * @param {boolean} filterEnabled
     * @returns {}
     */
    static availableLoginMethods(
        exclude: ('email')[] = [],
        filterEnabled = true,
    ) {
        const methods = {
            email: true,
            vk: IS_MARKETPLACE,
            tg: !!window.exode.common?.user.auth.telegramLoginBotName,
            apple: IS_MARKETPLACE && IS_NATIVE && OS_PLATFORM === 'ios',
        } as const;

        return _.omitBy(_.omit(methods, exclude), (v) => filterEnabled ? !v : false) as Record<keyof typeof methods, boolean>;
    }

}


export { UserAuthService };
