/**
 * ContentElementContext
 *
 * @author: exode <hello@exode.ru>
 */

import React, { createContext, ReactElement, useContext } from 'react';

import { ContentElementType } from '@/codegen/graphql';

import {
    AudioEditProps,
    AudioShowProps,
    ButtonEditProps,
    ButtonShowProps,
    ChecklistEditProps,
    ChecklistShowProps,
    CommonEditProps,
    CommonShowProps,
    EditorJsBlockEditProps,
    EditorJsBlockShowProps,
    NotionPageEditProps,
    NotionPageShowProps,
    VideoEditProps,
    VideoShowProps,
} from '@/shared/types';


interface ContentElementContext {
    edit?: {
        common?: CommonEditProps;
        [ContentElementType.ActionContextBanner]?: CommonEditProps;
        [ContentElementType.AdContextBanner]?: CommonEditProps;
        [ContentElementType.Audio]?: AudioEditProps;
        [ContentElementType.Button]?: ButtonEditProps;
        [ContentElementType.ChatBotContent]?: CommonEditProps;
        [ContentElementType.Checklist]?: ChecklistEditProps;
        [ContentElementType.Survey]?: Record<any, any>;
        [ContentElementType.Text]?: CommonEditProps;
        [ContentElementType.EditorJsBlock]?: EditorJsBlockEditProps;
        [ContentElementType.NotionPage]?: NotionPageEditProps;
        [ContentElementType.Video]?: VideoEditProps;
    };

    show?: {
        common?: CommonShowProps;
        [ContentElementType.ActionContextBanner]?: CommonShowProps;
        [ContentElementType.AdContextBanner]?: CommonShowProps;
        [ContentElementType.Audio]?: AudioShowProps;
        [ContentElementType.Button]?: ButtonShowProps;
        [ContentElementType.ChatBotContent]?: CommonShowProps;
        [ContentElementType.Checklist]?: ChecklistShowProps;
        [ContentElementType.Survey]?: Record<any, any>;
        [ContentElementType.Text]?: CommonShowProps;
        [ContentElementType.EditorJsBlock]?: EditorJsBlockShowProps;
        [ContentElementType.NotionPage]?: NotionPageShowProps;
        [ContentElementType.Video]?: VideoShowProps;
    };
}

interface ContentElementProviderProps {
    value: ContentElementContext;
    children: ReactElement | ReactElement[];
}


/**
 * ContentElement context
 */
const ContentElementContext = createContext<ContentElementContext>({
    edit: {},
    show: {},
});

/**
 * ContentElement provider
 * @param {ContentElementProviderProps} props
 * @constructor
 */
const ContentElementProvider = (props: ContentElementProviderProps) => {
    return (
        <ContentElementContext.Provider value={props.value}>
            {props.children}
        </ContentElementContext.Provider>
    );
};

/**
 * UseContentElementContext hook
 * @returns {ContentElementContext}
 */
const useContentElementContext = (): ContentElementContext => useContext(ContentElementContext);


export { ContentElementProvider, useContentElementContext };
