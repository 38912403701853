/**
 * Content types
 *
 * @author: exode <hello@exode.ru>
 */

import { ReactElement } from 'react';

import { useI18n } from '@/hooks/core';
import { GqlResult } from '@/types/graphql';

import { ContentCategoryFindManyQueryResult, ContentElementType } from '@/codegen/graphql';

import { IconNotion } from '@/images/icons';

import {
    Icon20HandPointUpOutline,
    Icon20ListBulletOutline,
    Icon20ServicesOutline,
    Icon20VideoAddSquareOutline,
    Icon20VoiceOutline,
} from '@vkontakte/icons';


export type ContentCategoryItem = NonNullable<GqlResult<ContentCategoryFindManyQueryResult>['contentCategoryFindMany']['items']>[number];


export enum ContentCategorySlug {
    Ege = 'ege',
    Marketing = 'marketing',
    Finance = 'finance',
    Development = 'development',
    PersonalGrowth = 'personal-growth',
    Music = 'music',
    Design = 'design',
    Photo = 'photo',
    Video = 'video',
    Preschool = 'preschool',
    Language = 'language',
    Sport = 'sport',
    Oge = 'oge',
}

export const contentElementType = () => {

    const { t } = useI18n('types.content');

    return {
        [ContentElementType.Video]: t('video'),
        [ContentElementType.ActionContextBanner]: t('actionContextBanner'),
        [ContentElementType.AdContextBanner]: t('adContextBanner'),
        [ContentElementType.Audio]: t('audio'),
        [ContentElementType.Button]: t('button'),
        [ContentElementType.ChatBotContent]: t('chatBotContent'),
        [ContentElementType.Checklist]: t('checklist'),
        [ContentElementType.EditorJsBlock]: t('editorJsBlock'),
        [ContentElementType.NotionPage]: t('notionPage'),
        [ContentElementType.Survey]: t('survey'),
        [ContentElementType.Text]: t('text'),
    } as const;
};

export const contentElementDescription = () => {

    const { t } = useI18n('types.content');

    return {
        [ContentElementType.Video]: t('videoDescription'),
        [ContentElementType.ActionContextBanner]: t('actionContextBannerDescription'),
        [ContentElementType.AdContextBanner]: t('adContextBannerDescription'),
        [ContentElementType.Audio]: t('audioDescription'),
        [ContentElementType.Button]: t('buttonDescription'),
        [ContentElementType.ChatBotContent]: t('chatBotContentDescription'),
        [ContentElementType.Checklist]: t('checklistDescription'),
        [ContentElementType.EditorJsBlock]: t('editorJsBlockDescription'),
        [ContentElementType.NotionPage]: t('notionPageDescription'),
        [ContentElementType.Survey]: t('surveyDescription'),
        [ContentElementType.Text]: t('textDescription'),
    } as const;
};

export const contentElementTypeIcon: { [key in ContentElementType]?: ReactElement } = {
    [ContentElementType.Audio]: <Icon20VoiceOutline/>,
    [ContentElementType.Button]: <Icon20HandPointUpOutline/>,
    [ContentElementType.Checklist]: <Icon20ListBulletOutline/>,
    [ContentElementType.EditorJsBlock]: <Icon20ServicesOutline/>,
    [ContentElementType.NotionPage]: <IconNotion width={20} height={20}/>,
    [ContentElementType.Video]: <Icon20VideoAddSquareOutline/>,
};
