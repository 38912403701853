/**
 * UploadWrapperButtonPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { Spinner } from '@exode.ru/vkui';
import { IconButton } from '@mui/material';
import { TextTooltip } from '@exode.ru/vkui/unstable';
import { Icon28PicturePenOutline, Icon28PicturePlusOutline } from '@vkontakte/icons';


interface Props {
    loading?: boolean;
    progress?: number;
    wrapperUrl?: string;
}


const UploadWrapperButtonPart = (props: Props) => {

    const { loading, progress, wrapperUrl, ...rest } = props;

    const { t } = useI18n('components.ContentElement.edit');

    return (
        <TextTooltip placement="left"
                     shown={!!loading || undefined}
                     text={loading ? `${progress} %` : t('setUpWrapper')}>
            <div className="flex items-center justify-center gap-2" {...rest}>
                <If is={!loading}>
                    <IconButton>
                        {wrapperUrl
                            ? <Icon28PicturePenOutline width={24} height={24} fill="var(--accent)"/>
                            : <Icon28PicturePlusOutline width={24} height={24} fill="var(--accent)"/>
                        }
                    </IconButton>
                </If>

                <If is={!!loading}>
                    <Spinner className="text-accent"/>
                </If>
            </div>
        </TextTooltip>
    );
};


export { UploadWrapperButtonPart };
