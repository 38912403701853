/**
 * TextAccordion
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode, useState } from 'react';

import { If } from '@/cutils';

import { Text } from '@exode.ru/vkui';
import { Icon24ChevronDown, Icon24ChevronUp } from '@vkontakte/icons';


interface Props {
    expanded: boolean;
    text: string;
    children?: ReactNode;
    className?: string;
}


const TextAccordion = (props: Props) => {

    const { text, children, className } = props;

    const [ isExpanded, setIsExpanded ] = useState(props.expanded);

    return (
        <>
            <Text onClick={() => setIsExpanded(!isExpanded)} className={[
                className,
                'flex items-center gap-2 text-accent cursor-pointer fs-14 mb-2 select-none',
            ].join(' ')}>
                {!isExpanded ? <Icon24ChevronDown/> : <Icon24ChevronUp/>}

                <span>{text}</span>
            </Text>

            <If is={isExpanded}>
                <>{children}</>
            </If>
        </>
    );
};


export { TextAccordion };
