/**
 * CreateGroupView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Form, Formik } from 'formik';

import { Field } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { useLocation } from '@/router/index';
import { useGroupManage } from '@/hooks/apollo';
import { Router } from '@/services/Utils/Router';

import { Button, FormItem, Input } from '@exode.ru/vkui';

import { StickyButton } from '@/components/Atoms/StickyButton';
import { FieldBottomLimiter } from '@/components/Atoms/FieldBottomLimiter';

import { UsersSelectView } from '@/pages/Manage/School/Users';


const CreateGroupView = () => {

    const { t } = useI18n('pages.Manage.Courses.Groups.GroupsMain.views');

    const { route: { params } } = useLocation();

    const { createGroup, createGroupLoading } = useGroupManage();

    return (
        <Formik onSubmit={(values) => {
            createGroup(values, (group) => {
                Router.pushPage(
                    '/manage/course/:courseId([0-9]+)/groups/:page([0-9]+)/:groupId([0-9]+)/settings',
                    { ..._.pick(params, [ 'courseId' ]), groupId: `${group.id}` },
                    true,
                );
            });
        }} initialValues={{
            name: '',
            memberUserIds: [],
            productId: +params.productId,
        }}>
            {({ handleSubmit, values, handleChange, setFieldValue }) => (
                <Form onSubmit={handleSubmit} className="flex flex-col justify-between h-full px-3">
                    <UsersSelectView userSelectedChipsProps={{ scrollWrapperClassName: 'h-[85px]' }}
                                     onChange={(profiles) => {
                                         setFieldValue('memberUserIds', _.map(profiles, 'user.id'));
                                     }}
                                     before={(
                                         <FormItem top={t('groupName')} className="p-0" bottom={(
                                             <FieldBottomLimiter maxLength={100} value={values.name}/>
                                         )}>
                                             <Input name="name"
                                                    maxLength={100}
                                                    value={values.name}
                                                    placeholder={t('enterGroupName')}
                                                    onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                                         </FormItem>
                                     )}/>

                    <StickyButton className="px-0 pb-3">
                        <Button size="l"
                                stretched
                                type="submit"
                                className="mt-3"
                                loading={createGroupLoading}
                                disabled={createGroupLoading || !values.name}>
                            {t('createGroup')}
                        </Button>
                    </StickyButton>
                </Form>
            )}
        </Formik>
    );
};


export { CreateGroupView };
