/**
 * CoursePriceCard component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { HTMLAttributes } from 'react';

import { observer } from '@/pages/Core';

import { If } from '@/cutils';
import { Time } from '@/utils';
import { useI18n, useNative } from '@/hooks/core';

import { Router } from '@/services/Utils/Router';

import { CourseFindOne } from '@/types/course';
import { ProductPriceMode } from '@/codegen/graphql';

import { Button, SimpleCell } from '@exode.ru/vkui';
import { TextTooltip } from '@exode.ru/vkui/unstable';
import { Icon16InfoOutline, Icon16ShoppingCartOutline } from '@vkontakte/icons';

import { PriceAmountPart } from './parts/PriceAmountPart';
import { PriceTagPart } from './parts/PriceTagPart';
import { PriceAvatarPart } from './parts/PriceAvatarPart';

import { Container } from './CoursePriceCard.styled';


export interface CoursePriceCardProps extends HTMLAttributes<HTMLDivElement> {
    productId: number;
    course: CourseFindOne;
    currency: NonNullable<CourseFindOne['product']>['currency'];
    price: NonNullable<NonNullable<CourseFindOne['product']>['prices']>[number];
    width?: string;
    height?: string;
    flexColReverse?: boolean;
}


const CoursePriceCard = observer((props: CoursePriceCardProps) => {

    const {
        width,
        price,
        height,
        productId,
        currency,
        course,
        flexColReverse = false,
        price: {
            mode,
            tags,
            icon,
            title,
            amount,
            actualTo,
            previousAmount,
            amountExchangeRates,
            description: priceDescription,
        },
        ...rest
    } = props;

    const { t } = useI18n('components.Product.PriceCard');

    const { triggerHaptic } = useNative();

    const proceedToPay = async () => {
        triggerHaptic();

        Router.pushPage('/pay/:productId([0-9]+)/:priceId([0-9]+)', {
            productId: `${productId}`,
            priceId: `${price.id}`,
        });
    };

    return (
        <Container width={width} height={height} {...rest} data-test="product.price-card" className="p-1 thin-border">
            <div onClick={proceedToPay} className="z-20 rounded-2xl h-full flex flex-col cursor-pointer">
                <SimpleCell multiline
                            before={<PriceAvatarPart icon={icon}/>}
                            className="pointer-events-none items-start"
                            subtitle={(<span className="flex mt-1 text-primary">{priceDescription}</span>)}>
                    <span className="text-primary flex font-bold fs-12">
                        {title}
                    </span>

                    <div className="flex items-center">
                        <PriceAmountPart amount={amount}
                                         currency={currency}
                                         previousAmount={previousAmount}
                                         amountExchangeRates={amountExchangeRates}/>
                    </div>
                </SimpleCell>

                <If is={mode === ProductPriceMode.SelfDefinition}>
                    <div className="flex flex-wrap gap-2 px-4 mt-1 mb-2">
                        <TextTooltip offsetSkidding={8}
                                     placement="bottom-start"
                                     className="max-w-[180px]"
                                     text={t('priceActivityIsLimitedToSpecifiedDate')}>
                            <div className="min-w-[16px]">
                                <Icon16InfoOutline fill="var(--icon_secondary)"/>
                            </div>
                        </TextTooltip>

                        <span className="flex text-secondary fs-12">
                            {t('priceIsActiveTill', { date: Time.parseDate(actualTo, 'DD.MM.YYYY HH:mm') })}
                        </span>
                    </div>
                </If>

                <div className="mb-2"/>

                <div className={[ 'flex flex-col flex-1', flexColReverse ? 'flex-col-reverse' : '' ].join(' ')}>
                    <div className={[
                        'flex flex-col px-4 mb-4 gap-3',
                        flexColReverse ? 'mb-auto pb-4' : 'mt-1',
                    ].join(' ')}>
                        {[
                            ...tags.filter(e => !e.startsWith('-')),
                            ...tags.filter(e => e.startsWith('-')),
                        ].map((item, i) => <PriceTagPart key={i} tag={item}/>)}
                    </div>

                    <div className="flex sticky bottom-2 bg-content button-rounded z-[101] items-end w-[calc(100%_-_32px)] mx-4 mb-4">
                        <Button size="l"
                                stretched
                                mode="outline"
                                appearance="positive"
                                onClick={proceedToPay}
                                data-test="cart.payment.checkout"
                                className="h-fit bg-gradient-to-br"
                                before={<Icon16ShoppingCartOutline/>}>
                            {t('selectPrice')}
                        </Button>
                    </div>
                </div>
            </div>
        </Container>
    );
});


export { CoursePriceCard };
