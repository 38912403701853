/**
 * EnrollBannerItem
 *
 * @author: exode <hello@exode.ru>
 */

import React, { MutableRefObject, useEffect } from 'react';

import { useLocation } from '@/router/index';

import { observer, PreferenceStore } from '@/store/preference/preference';

import { useI18n, useNative } from '@/hooks/core';
import { ScrollHelper } from '@/helpers/ui';
import { CourseFindOne } from '@/types/course';
import { DocumentEvent } from '@/types/window';
import { useCourseStart } from '@/hooks/apollo';
import { Router } from '@/services/Utils/Router';

import { Icon16FireVerified, Icon16ShoppingCartOutline, Icon28AddSquareOutline } from '@vkontakte/icons';

import { PriceAmountPart } from '@/components/Product/PriceCard';
import { GradientButtonCard } from '@/components/Atoms/GradientButtonCard';
import { getCourseCardColors } from '@/components/Course/CourseCard/helper';


interface Props {
    course: CourseFindOne;
    pricesRef?: MutableRefObject<HTMLDivElement | null>;
    onePrice?: CourseFindOne['product']['prices'][number] | null;
}


const EnrollBannerItem = observer((props: Props) => {

    const {
        course,
        pricesRef,
        onePrice,
        course: {
            product,
            product: { currentOnSaleLaunch },
        },
    } = props;

    const { t } = useI18n('pages.Course.Information');

    const {
        route: {
            pageId,
            params: { modal, enrollAfterAuth },
        },
    } = useLocation();

    const { triggerHaptic } = useNative();

    const {
        onAccessForFree,
        onAccessForFreeCalled,
        onAccessForFreeLoading,
    } = useCourseStart();

    const proceedToPay = async () => {
        if (onePrice) {
            triggerHaptic();

            Router.pushPage('/pay/:productId([0-9]+)/:priceId([0-9]+)', {
                productId: `${product.id}`,
                priceId: `${onePrice.id}`,
            });
        }
    };

    const scrollToPrices = () => {
        if (pricesRef?.current) {
            ScrollHelper.to(
                ScrollHelper.calcTopToElement(pricesRef.current),
                true,
            );
        }
    };

    const handleClick = (
        !product.isFree
            ? (onePrice ? proceedToPay : scrollToPrices)
            : () => onAccessForFree(currentOnSaleLaunch?.id)
    );

    const handleFreeEnrollment = () => {
        Router.updateParams({ enrollAfterAuth: 'false' });

        onAccessForFree(currentOnSaleLaunch?.id);
    };

    useEffect(() => {
        if (enrollAfterAuth === 'true'
            && !course?.isParticipant
            && !onAccessForFreeCalled
        ) {
            if ([
                modal === 'user-main-info',
                pageId === '/profile/main-info',
            ].some((e) => e)) {
                document.addEventListener(
                    DocumentEvent.ProfilePersonalInfoFilledSuccess,
                    handleFreeEnrollment,
                    { once: true },
                );
            }

            return () => {
                document.removeEventListener(
                    DocumentEvent.ProfilePersonalInfoFilledSuccess,
                    handleFreeEnrollment,
                );
            };
        }
    }, [ enrollAfterAuth ]);

    return (
        <div className={[
            'mt-5 mb-2',
            (product.isFree || onePrice) ? 'sticky z-[101]' : '',
        ].join(' ')} style={{
            top: (product.isFree || onePrice)
                ? 'calc(var(--panelheader\_height) + 12px)'
                : undefined,
        }}>
            <GradientButtonCard transparent
                                wholeIsClickable
                                onClick={handleClick}
                                data-test="course.payButton"
                                isLoading={onAccessForFreeLoading}
                                gradient={[ 'd:!transparent' ].join(' ')}
                                className="bg-content-75--blur card-clear-after"
                                subheader={t('willStartTrainingAfterEnrollment')}
                                afterIcon={<Icon28AddSquareOutline fill="text-primary"/>}
                                style={{ background: getCourseCardColors(course, PreferenceStore.isDark) }}
                                header={(
                                    <div className="flex gap-1">
                                        {(
                                            product.isFree
                                                ? t('takeCourseForFree')
                                                : t('enrollForCourse')
                                        )}

                                        {onePrice && (
                                            <>
                                                <span className="fs-13">{' • '}</span>
                                                <PriceAmountPart currenClassName="mr-1"
                                                                 amount={onePrice.amount}
                                                                 currency={product.currency}
                                                                 previousAmount={onePrice.previousAmount}
                                                                 amountExchangeRates={onePrice.amountExchangeRates}
                                                                 previousClassName="line-through-diagonally text-primary fs-12"/>
                                            </>
                                        )}
                                    </div>
                                )}
                                button={{
                                    appearance: 'overlay',
                                    children: product.isFree
                                        ? t('startEducation')
                                        : (onePrice ? t('pay') : t('tariffs')),
                                    before: (
                                        product.isFree
                                            ? <Icon16FireVerified/>
                                            : <Icon16ShoppingCartOutline/>
                                    ),
                                }}/>
        </div>
    );
});


export { EnrollBannerItem };
