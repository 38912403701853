/**
 * TableView
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import React from 'react';

import { Link } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { PracticeAttemptItem, practiceAttemptStatus } from '@/types/practice';

import { Caption, Text } from '@exode.ru/vkui';

import { User } from '@/components/Atoms/User';
import { Table } from '@/components/Atoms/Table';
import { UserAvatar } from '@/components/Atoms/UserAvatar';

import { PracticeAttemptStatusItem } from '@/pages/Manage/Homework';


interface Props {
    items: PracticeAttemptItem[];
}


const TableView = (props: Props) => {

    const { items } = props;

    const { t } = useI18n('pages.Manage.Homework.items');

    const practiceAttemptStatuses = practiceAttemptStatus();

    return (
        <Table.Wrapper grid="1fr 1fr 1fr 1fr" gap="0.5rem">
            <Table.Header>
                {[
                    t('userName'),
                    t('lesson'),
                    t('sentToReviewAt'),
                    t('status'),
                ].map((column, index) => (
                    <Text key={index} className="font-medium">
                        {column}
                    </Text>
                ))}
            </Table.Header>

            {items.map(({ id, sentToReviewAt, user, curator, status, practice, message }) => (
                <Link disabled={!message?.chat.id} pushPage={{
                    id: '/manage/practice/chat',
                    params: {
                        attemptId: `${id}`,
                        chatId: `${message?.chat.id}`,
                    },
                }}>
                    <Table.Cell key={id} disabled={!message?.chat.id}>
                        <div className="flex items-center gap-2">
                            <UserAvatar size={42}
                                        userId={user.id}
                                        name={user.profile?.fullName || ''}
                                        src={user.profile?.avatar.medium || ''}/>

                            <div className="flex flex-col">
                                <Text weight="regular" className="text-primary">
                                    <User.Name profile={user.profile}/>
                                </Text>

                                <Caption className="text-secondary">
                                    {curator?.user?.profile?.fullNameShort || t('withoutCurator')}
                                </Caption>
                            </div>
                        </div>

                        <Text className="line-clamp-1 text-primary">
                            {practice.lesson.name}

                            <Caption className="text-secondary">
                                {practice.lesson.course.name}
                            </Caption>
                        </Text>

                        <Text>
                            {!sentToReviewAt ? '—' : moment(sentToReviewAt).format('DD MMM YYYY HH:mm')}
                        </Text>

                        <div className="flex items-center gap-2">
                            <PracticeAttemptStatusItem status={status} className="p-1 mr-1"/>

                            <span className="text-primary line-clamp-1">
                                {status && practiceAttemptStatuses[status].name}
                            </span>
                        </div>
                    </Table.Cell>
                </Link>
            ))}
        </Table.Wrapper>
    );
};


export { TableView };
