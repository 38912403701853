/**
 * UseProductPrice
 *
 * @author: exode <hello@exode.ru>
 */

import { ManageCoursePricingPageStore } from '@/pages/Manage/Courses/Launches/Pricing/store';

import { getGqlCause } from '@/api/graphql';
import { useBatchUpdateCacheFields, useI18n } from '@/hooks/core';
import { ProductLaunchItem, ProductPriceItem } from '@/types/product';

import {
    CreatePriceProductInput,
    ProductException,
    ProductFindManyPricesDocument,
    ProductFindManyPricesQuery,
    ProductPriceEntity,
    ProductPriceMode,
    UpdatePriceProductInput,
    useProductCreatePriceMutation,
    useProductUpdatePriceMutation,
} from '@/codegen/graphql';


interface Props {
    productId: number;
    launchId?: number;
    list: typeof ManageCoursePricingPageStore['state']['list'];
    sort: typeof ManageCoursePricingPageStore['state']['sort'];
    onCompleted?: () => void;
}


export const useProductPrice = (props: Props) => {

    const { productId, launchId, list, sort, onCompleted } = props;

    const { t } = useI18n('hooks.apollo.product');

    const variables = { list, sort, filter: { productId, launchIds: [ launchId ] } };

    const priceExceptionMap: Record<'title', Record<string, string>> = {
        title: {
            [ProductException.PriceTitleIsNotUniq]: t('tariffWithSuchTitleAlreadyExists'),
        },
    };

    const [
        _createPrice,
        { loading: createPriceLoading, error: createPriceError },
    ] = useProductCreatePriceMutation({
        onCompleted: () => onCompleted?.(),
        onError: error => console.error(error),
        update: (cache, { data }) => {
            if (!data) {
                return;
            }

            const cachedPrices = cache.readQuery<ProductFindManyPricesQuery>({
                query: ProductFindManyPricesDocument,
                variables,
            });

            if (!cachedPrices) {
                return console.warn('[Cache]: cachedPrices отсутствуют в кэше');
            }

            cache.writeQuery<ProductFindManyPricesQuery>({
                query: ProductFindManyPricesDocument,
                variables,
                data: {
                    productPriceFindMany: {
                        __typename: 'ListPriceProductOutput',
                        pages: cachedPrices?.productPriceFindMany.pages ?? 1,
                        count: (cachedPrices?.productPriceFindMany.count ?? 1) + 1,
                        items: [ data?.productPriceCreate, ...(cachedPrices?.productPriceFindMany.items ?? []) ],
                    },
                },
            });
        },
    });

    const createPrice = (
        productId: number,
        price: CreatePriceProductInput,
    ) => {
        return _createPrice({ variables: { price, productId } });
    };

    const [
        _updatePrice,
        { loading: updatePriceLoading, error: updatePriceError },
    ] = useProductUpdatePriceMutation({
        onCompleted: () => onCompleted?.(),
        onError: error => console.error(error),
        update: (cache, { data }) => {
            data && cache.modify({
                id: `ProductPriceEntity:${data?.productPriceUpdate.id}`,
                fields: useBatchUpdateCacheFields<ProductPriceEntity>(data?.productPriceUpdate),
            });
        },
    });

    const updatePrice = (
        priceId: number,
        price: UpdatePriceProductInput,
    ) => {
        return _updatePrice({ variables: { priceId, price } });
    };

    const getInitialValues = (
        price?: ProductPriceItem,
        launch?: ProductLaunchItem | ProductPriceItem['launch'],
    ) => {
        return {
            mode: price?.mode || ProductPriceMode.AccordingToGroup,
            tags: price?.tags || [],
            title: price?.title || '',
            amount: price?.amount || '',
            active: !!price?.active,
            accessDays: price?.accessDays,
            description: price?.description || '',
            previousAmount: price?.previousAmount || '',
            infinityAccess: price?.infinityAccess || launch?.infinityAccess,
            activeFrom: price?.activeFrom || price?.actualFrom || launch?.saleStartAt,
            activeTo: price?.activeTo || price?.actualTo || launch?.saleFinishAt,
            icon: price?.icon || '',
            groupId: price?.group?.id || null,
        };
    };

    return {
        createPrice,
        createPriceError,
        createPriceLoading,
        updatePrice,
        updatePriceLoading,
        priceExceptionMap,
        getInitialValues,
        createPriceCause: getGqlCause(createPriceError),
        updatePriceCause: getGqlCause(updatePriceError),
    };
};
