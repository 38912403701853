/**
 * ChatContext
 *
 * @author: exode <hello@exode.ru>
 */

import React, { createContext, ReactElement, useContext } from 'react';

import { useChatInfo } from '@/hooks/apollo';


export type ChatContext = ReturnType<typeof useChatInfo> | null;

interface ChatProviderProps {
    value: ChatContext;
    children: ReactElement | ReactElement[];
}


/**
 * Chat context
 */
const ChatContext = createContext<ChatContext>(null);

/**
 * Chat provider
 * @constructor
 */
const ChatProvider = (props: ChatProviderProps) => {
    return (
        <ChatContext.Provider value={props.value}>
            {props.children}
        </ChatContext.Provider>
    );
};

/**
 * UseChatContext hook
 * @returns {ChatContext}
 */
const useChatContext = (): ChatContext => useContext(ChatContext);


export { ChatProvider, useChatContext };
