/**
 * UseChatMessageRender
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { useStore } from '@/pages/Core';
import { ConfigStore } from '@/store/core/config';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import { ChatService } from '@/services/Chat/Chat';

import { If, SvgComponent } from '@/cutils';

import { ChatMessageStatus } from '@/codegen/graphql';
import { ChatMessageItem, ChatMessageItemNullable, chatMessageStatusIcon } from '@/types/chat';

import { Icon16CheckDoubleOutline } from '@vkontakte/icons';


interface Props {
    chatId: number;
    message: ChatMessageItem;
    messages: ChatMessageItemNullable;
}


export const useChatMessageRender = (props: Props) => {

    const { chatId, message, messages } = props;

    const { store } = useStore(ChatDialogsPageStore);

    const [ isHovered, setIsHovered ] = useState<number | null>(null);

    const isDesktop = ConfigStore.isDesktop;
    const isCompact = ChatService.hasGapByInterval(message.id, messages || []);
    const isSelected = store.state.selectedMessages?.[chatId]?.find((item) => item.id === message.id);

    const isSending = message.status === ChatMessageStatus.Sending;
    const isSent = [ ChatMessageStatus.Sent, ChatMessageStatus.Scheduled ].includes(message.status);
    const unsentMessage = store.state.unsentMessages[chatId]?.find((item) => item.messageId === message.id);

    const selectIconClassName = [
        'mb-1 mr-2 self-start cursor-pointer m:hidden sticky top-4 bottom-4',
        isCompact ? '!my-auto' : 'mt-[10px]',
        isHovered || isSelected ? 'visible' : 'invisible',
    ].join(' ');

    const statusElement = (
        <If is={message.isMine}>
            <div className={[
                'text-accent',
                isCompact ? 'relative' : 'absolute',
                message.editedAt && !isCompact ? 'left-[40px]' : 'left-0',
            ].join(' ')}>
                <SvgComponent svgProps={{
                    width: 18,
                    height: 18,
                }} element={(
                    <>
                        <If is={message.isRead}><Icon16CheckDoubleOutline/></If>
                        <If is={!message.isRead}>{chatMessageStatusIcon[message.status]}</If>
                    </>
                )}/>
            </div>
        </If>
    );

    return {
        isSent,
        isSending,
        isCompact,
        isSelected,
        isDesktop,
        isHovered,
        setIsHovered,
        unsentMessage,
        statusElement,
        selectIconClassName,
    };
};
