/**
 * InputView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useHotkeys } from 'react-hotkeys-hook';
import { EmojiClickData } from 'emoji-picker-react';

import { If, Notify, Responsive } from '@/cutils';
import { useFilePaste, useI18n, useUploadFiles } from '@/hooks/core';
import { useChatInfoContext, useChatInput, UseChatInputProps, useChatMember } from '@/hooks/apollo';

import { DocumentEvent } from '@/types/window';

import { Router } from '@/services/Utils/Router';
import { ChatService } from '@/services/Chat/Chat';

import { ConfigStore } from '@/store/core/config';
import { RouterStore } from '@/store/core/router';
import { observer, useStore } from '@/pages/Core';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import { Dropdown } from '@exode.ru/vkui/dist/unstable';
import { Button, Spinner, WriteBar, WriteBarIcon } from '@exode.ru/vkui';
import { Icon20MuteOutline, Icon20VolumeOutline, Icon24ErrorCircleFillRed, Icon28SmileOutline } from '@vkontakte/icons';

import { EmojiPicker } from '@/components/Atoms/EmojiPicker';

import { ReplyAboveInput } from '../items/ReplyAboveInput';
import { PreUploadedFilesView } from './PreUploadedFilesView';
import { MessagesFileUploadIconView } from './MessagesFileUploadIconView';

import styles from '../ChatDialogsPage.module.scss';


export interface InputViewProps {
    value?: string;
    isBlocked?: boolean;
    placeholder?: string;
    onKeyPress?: (event: React.KeyboardEvent, message: string) => void;
    useChatInputProps?: Partial<UseChatInputProps>;
}


const InputView = observer((props: InputViewProps) => {

    const {
        isBlocked,
        onKeyPress,
        placeholder,
        useChatInputProps,
    } = props;

    const {
        chat,
        chatId,
        inputRef,
        personalUserId,
        cancelEdit,
        scrollToBottom,
        handleClearReply,
        resetSelectedMessage,
        setCreateChatIsLoading,
    } = useChatInfoContext();

    const { t } = useI18n('pages.Chat.Dialog');

    const { input, store } = useStore(ChatDialogsPageStore);

    const [ showPicker, setShowPicker ] = useState(false);

    const writeBarRef = useRef<HTMLTextAreaElement | null>(null);

    const { updateChatMember, updateChatMemberLoading } = useChatMember();

    const {
        previews,
        setPreviews,
        loadFilesWithPreviews,
    } = useUploadFiles({ maxFiles: 10 });

    const {
        handleSend,
        handleTyping,
        createChatLoading,
        editChatMessageLoading,
    } = useChatInput({
        ...useChatInputProps,
        chatId,
        previews,
        cancelEdit,
        setPreviews,
        scrollToBottom,
        personalUserId,
        handleClearReply,
        setShowPicker,
        defaultValue: props.value,
    });

    const onEmojiClick = (emojiObject: EmojiClickData) => {
        const cursor = writeBarRef.current?.selectionStart;
        const newCursor = (cursor ?? 0) + emojiObject.emoji.length;
        const message = input.message.slice(0, cursor) + emojiObject.emoji + input.message.slice(cursor);

        store.setInput('message', message);
        setTimeout(() => writeBarRef.current?.setSelectionRange(newCursor, newCursor), 10);
    };

    const handleKeyPress = async (event: React.KeyboardEvent) => {
        onKeyPress?.(event, input.message);

        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();

            await handleSend();
        }

        if (event.key === 'Escape') {
            input.isEditing
                ? cancelEdit()
                : handleEscape(event);
        }

        if (event.key === 'ArrowUp') {
            if (!input.message.length) {
                document.dispatchEvent(
                    new CustomEvent(DocumentEvent.ChatTryEditLastMessage),
                );
            }
        }
    };

    const handleEscape = (event: KeyboardEvent | React.KeyboardEvent) => {
        const { hasOverlay, params: { photoViewer } } = RouterStore;

        if (hasOverlay || photoViewer === 'true') {
            return;
        }

        event.stopPropagation();
        event.preventDefault();

        if (input.isEditing) {
            store.setInput('isEditing', 0);
            store.setInput('message', '');
        } else if (!_.isEmpty(store.state.selectedMessages)) {
            resetSelectedMessage();
        } else {
            Router.pushPage('/chat');
        }
    };

    useFilePaste({
        onFilePaste: (files) => {
            if (previews.length >= 10) {
                return Notify.toast(t('maxIs10Files'));
            }

            setPreviews((prev) => ([ ...prev, ...files ]));
        },
    });

    useHotkeys('esc', (e: KeyboardEvent) => {
        handleEscape(e);
    });

    useHotkeys('*', (e) => {
        if ((e.target as any).nodeName?.toLowerCase() !== 'input') {
            const ignore = [
                e.altKey,
                e.ctrlKey,
                e.metaKey,
            ];

            if (ignore.every(e => !e)) {
                writeBarRef.current?.focus();
            }
        }
    }, [ writeBarRef ]);

    useEffect(() => {
        setCreateChatIsLoading?.(createChatLoading);
    }, [ createChatLoading ]);

    useLayoutEffect(() => {
        setPreviews([]);

        store.restoreDraftMessage(chatId);
        personalUserId && store.setInput('message', '');
    }, [ chatId, personalUserId ]);

    useLayoutEffect(() => {
        ConfigStore.isDesktop && writeBarRef.current?.focus();
    }, [ chatId ]);

    return (
        <>
            <If is={!!previews.length}>
                <PreUploadedFilesView previews={previews} setPreviews={setPreviews}/>
            </If>

            <If is={!!chatId && !_.isEmpty(input.replyMessage[chatId])}>
                <ReplyAboveInput chatId={chatId!}/>
            </If>

            <If is={ChatService.isChannelButNotAdmin(chat)}>
                <div className={[
                    '!mt-0 m:w-[calc(100vw_-_2rem)] m:fixed m:bottom-tabbar m:px-[1rem] bg-content',
                    '!z-0 m:!z-10 thin-border-top border-b-transparent d:px-2.5 py-2.5 d:pl-[12px]',
                ].join(' ')}>
                    <Button stretched
                            size="m"
                            appearance="neutral"
                            loading={updateChatMemberLoading}
                            disabled={updateChatMemberLoading}
                            onClick={() => updateChatMember(chatId || 0, { muted: !chat?.myMember?.muted })}
                            before={(
                                chat?.myMember?.muted
                                    ? <Icon20VolumeOutline/>
                                    : <Icon20MuteOutline/>
                            )}>
                        {chat?.myMember?.muted
                            ? t('turnOnNotification')
                            : t('turnOffNotification')
                        }
                    </Button>
                </div>
            </If>

            <If is={!chat || !!chat && ChatService.canSendMessage(chat)}>
                <WriteBar disabled={isBlocked}
                          data-test="chat.writebar"
                          onKeyDown={handleKeyPress}
                          value={!isBlocked ? input.message : ''}
                          placeholder={placeholder || t('messageInputPlaceholder')}
                          className={`${styles.writeBar} flex flex-col thin-border-top`}
                          getRef={(ref) => {
                              inputRef.current = ref;
                              writeBarRef.current = ref;
                          }}
                          onFocus={(e) => (
                              ConfigStore.isDesktop && e.currentTarget.setSelectionRange(
                                  e.currentTarget.value.length,
                                  e.currentTarget.value.length,
                              )
                          )}
                          onChange={async ({ target: { value } }) => {
                              store.setInput('message', value);

                              if (!input.isEditing) {
                                  store.saveDraftMessage(value, chatId);
                              }

                              if (chatId && !input.isEditing) {
                                  handleTyping();
                              }
                          }}
                          before={(
                              <>
                                  <If is={!!isBlocked}>
                                      <Icon24ErrorCircleFillRed/>
                                  </If>

                                  <If is={!isBlocked}>
                                      <MessagesFileUploadIconView cancelEdit={cancelEdit}
                                                                  isEditing={!!input.isEditing}
                                                                  loadFilesWithPreviews={loadFilesWithPreviews}
                                                                  editChatMessageLoading={editChatMessageLoading}/>
                                  </If>
                              </>
                          )}
                          after={!isBlocked && (
                              <>
                                  <Responsive.Desktop>
                                      <Dropdown action="hover"
                                                placement="right"
                                                shown={showPicker}
                                                onShownChange={setShowPicker}
                                                content={(
                                                    <div className="absolute right-0 bottom-0 pb-4 z-20">
                                                        <EmojiPicker className="d:mr-4 d:mb-2"
                                                                     onEmojiClick={onEmojiClick}/>
                                                    </div>
                                                )}>
                                          <WriteBarIcon>
                                              <Icon28SmileOutline/>
                                          </WriteBarIcon>
                                      </Dropdown>
                                  </Responsive.Desktop>

                                  <If is={createChatLoading}>
                                      <WriteBarIcon>
                                          <Spinner/>
                                      </WriteBarIcon>
                                  </If>

                                  <If is={!createChatLoading}>
                                      <WriteBarIcon mode="send"
                                                    data-test="chat.send"
                                                    disabled={input.message.length === 0 && !previews?.length}
                                                    onClick={async () => {
                                                        inputRef.current?.focus();

                                                        await handleSend();
                                                    }}/>
                                  </If>
                              </>
                          )}/>
            </If>
        </>
    );
});


export { InputView };
